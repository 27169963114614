export const PluginInit = Symbol.for('initialize plugin');
export const PluginDetails = Symbol.for('plugin configuration');
export const PluginScopeParentScope = Symbol.for('parent scope');
export const PluginScopeData = Symbol.for('internal data');
export const PluginScopeSlotData = Symbol.for('slots data');
export const PluginScopeSyncData = Symbol.for('cached synchronization data');
export const PluginScopeCallSync = Symbol.for('update synchronized data');
export const PluginScopeRegisterChildrenScope = Symbol.for('register children scope');
export const PluginScopeUnregisterChildrenScope = Symbol.for('unregister children');
export const PluginScopeChildrenScope = Symbol.for('children scopes');
export const PluginScopeChildrenPluginMapScope = Symbol.for('map of plugin scopes');
export const PluginScopeDisableNewSync = Symbol.for('disable adding new watchers');
export const PluginScopeDisableNewSyncStatus = Symbol.for('new watchers status');
export const PluginScopeProducer = Symbol.for('identity of the producer in the PluginScope');
export const PluginScopeProducerDataCache = Symbol.for('map of slots data to producers');
export const PluginScopeProducerCache = Symbol.for('map of slots for producers');
export const PluginScopeAttachProducer = Symbol.for('attach the producer');
export const PluginScopeDestroy = Symbol.for('destroy the state produced through PluginScope');
export const PluginScopeScheduleUpdate = Symbol.for('schedule informing about change');
export const PluginScopeEmitChange = Symbol.for('inform about data change');
export const PluginScopeSubscribeChange = Symbol.for('subscribe to data change');
export const PluginScopeListeners = Symbol.for('subscription listeners list');
